import React, { useState } from "react";
import "./Home.css";
import Logo from "./Images/logo.png";
import { Link } from "react-router-dom";
// import DownArrow from "./Images/downarrow.png";
import Ham from "./Images/hamburger.png";
import Map from "./Images/Map.png";
import MapBg from "./Images/mapbg.mp4";
import Angel from "./Images/Angel.png";
import AngelVideo from "./Images/angel.webm";
import Explore from "./Images/comingsoon.png";
import Taroverse from "./Images/taroverse.png";
import TaroPara from "./Images/taropara.png";
import TaroParaMobile from "./Images/taropara_mobile.png";
import Taroland from "./Images/taroland.png";
import NftChest from "./Images/nftchest.png";
import NftChestMobile from "./Images/nftchest_mobile.png";
import NftBlur from "./Images/bgnftblur.png";
import NftHeroText from "./Images/nftherotext.png";
import NftHeroMobile from "./Images/nfthero_mobile.png";
import NftHeroImage from "./Images/nftheropic.png";
import TarotokenPara from "./Images/tarotoken.png";
import TarotokenMobile from "./Images/tarotoken_mobile.png";
import TarotokenLogo from "./Images/tarotokenlogo.png";
import Roadmap from "./Images/Roadmap.png";
import RoadmapOne from "./Images/roadmap1.png";
import RoadmapTwo from "./Images/roadmap2.png";
import RoadmapThree from "./Images/roadmap3.png";
import RoadmapFour from "./Images/roadmap4.png";
import RoadmapFive from "./Images/roadmap5.png";

import RoadmapOneMobile from "./Images/roadmap1_mobile.png";
// import RoadmapTwoMobile from "./Images/roadmap2_mobile.png";
// import RoadmapThreeMobile from "./Images/roadmap3_mobile.png";
// import RoadmapFourMobile from "./Images/roadmap4_mobile.png";
// import RoadmapFiveMobile from "./Images/roadmap5_mobile.png";

import RoadmapTwoMobileNew from "./Images/roadmap2new_mobile.png";
import RoadmapThreeMobileNew from "./Images/roadmap3new_mobile.png";
import RoadmapFourMobileNew from "./Images/roadmap4new_mobile.png";
import RoadmapFiveMobileNew from "./Images/roadmap5new_mobile.png";
import Line from "./Images/line.png";
import Cross from "./Images/cross.png";

import Partner from "./Images/partners.png";
import PartnerMobile from "./Images/partner_mobile.png";
import Investor from "./Images/investor.png";
import InvestorTwo from "./Images/investor2.png";
import FooterHero from "./Images/hero.png";
import FooterLogo from "./Images/footertaroverse.png";
import Instagram from "./Images/instagram.png";
import Twitter from "./Images/twitter.png";
import Telegram from "./Images/telegram.png";
import Discord from "./Images/discord.png";
import RoadmapTwoNew from "./Images/roadmap2new.png";
import RoadmapThreeNew from "./Images/roadmap3new.png";
import RoadmapFourNew from "./Images/roadmap4new.png";
import RoadmapFiveNew from "./Images/roadmap5new.png";
import ConnectWallet from "./Images/downloadbutton.png";
function Home() {
  const [isReadMoreTwo, setIsReadMoreTwo] = useState(true);
  const toggleReadMoreTwo = () => {
    setIsReadMoreTwo(!isReadMoreTwo);
  };
  const [isReadMoreThree, setIsReadMoreThree] = useState(true);
  const toggleReadMoreThree = () => {
    setIsReadMoreThree(!isReadMoreThree);
  };
  const [isReadMoreFour, setIsReadMoreFour] = useState(true);
  const toggleReadMoreFour = () => {
    setIsReadMoreFour(!isReadMoreFour);
  };
  const [isReadMoreOne, setIsReadMoreOne] = useState(true);
  const toggleReadMoreOne = () => {
    setIsReadMoreOne(!isReadMoreOne);
  };
  const [isReadMoreFive, setIsReadMoreFive] = useState(true);
  const toggleReadMoreFive = () => {
    setIsReadMoreFive(!isReadMoreFive);
  };
  const [showMenu, setShowMenu] = React.useState(false);
  const onClickShowMenu = () => {
    setShowMenu(!showMenu);
    if (showMenu) {
      setStyle("home_banner_map");
    } else {
      setStyle("home_banner_map_new");
    }
  };

  const [style, setStyle] = useState("home_banner_map");

  return (
    <>
      <div className="home_banner">
        <div class="navbar">
          <div class="logo">
            <img src={Logo} alt="" />
          </div>
          <div class="navbar_links">
            <Link to="./home">Home</Link>
            <a href="www.abc.com">Taroverse</a>
            <a href="www.abc.com">NFT</a>
            <a href="www.abc.com">Games</a>
            <a href="www.abc.com">Events</a>
            <a href="www.abc.com">
              Download <span class="arrow">▼</span>
            </a>
          </div>
          {/* <div class="download">
            <img src={DownArrow} alt="" />
          </div> */}
          <div class="navbar_links_login">
            <Link to="./login">Login</Link>
          </div>
          <a href="www.abc.com">
            <div class="wallet">
              <img src={ConnectWallet} alt="" />
            </div>
          </a>
        </div>
        {showMenu ? (
          <>
            <div class="mobile-navbar">
              <div class="mobile-logo">
                <img src={Logo} alt="" />
              </div>
              <div className="cross">
                <img src={Cross} alt="" onClick={onClickShowMenu} />
              </div>
              <div id="mobilemenu" class="mobile-navbar-links">
                <a href="www.abc.com">
                  <img src={ConnectWallet} alt="" />
                </a>
                <a href="www.abc.com" className="mobile-navbar-login">
                  Login
                </a>
                <div className="mobile-navbar-line"></div>
                <div class="mobile-navbar-links-two">
                  <a href="/home" className="mobile-navbar-loa">
                    Home
                  </a>
                  <a href="www.abc.com" className="mobile-navbar-lob">
                    Taroverse
                  </a>
                  <a href="www.abc.com" className="mobile-navbar-loc">
                    NFT
                  </a>
                  <a href="www.abc.com" className="mobile-navbar-lod">
                    Games
                  </a>
                  <a href="www.abc.com" className="mobile-navbar-loe">
                    Events
                  </a>
                  <a href="www.abc.com" className="mobile-navbar-lof">
                    Tokenomics
                  </a>
                  <a href="www.abc.com" className="mobile-navbar-log">
                    Download
                    <span class="arrow">▼</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="icon">
              <img src={Ham} alt="" onClick={onClickShowMenu} />
            </div>
          </>
        )}
        <div className={style}>
          <video id="home-background-video" autoPlay loop muted poster={Map}>
            <source src={MapBg} type="video/mp4" />
          </video>
        </div>
        <div className="home_banner_angel">
          <video id="" autoPlay loop muted poster={Angel}>
            <source src={AngelVideo} type="video/mp4" />
          </video>
        </div>
        <div className="home_banner_explore">
          <img src={Explore} alt="" />
        </div>
      </div>
      <div className="home_taro">
        <div className="home_taro_heading">
          <img src={Taroverse} alt="" />
        </div>
        <div className="home_taro_para">
          <img src={TaroPara} alt="" />
        </div>
        <div className="home_taro_para_mobile">
          <img src={TaroParaMobile} alt="" />
        </div>
        <div className="home_taro_banner">
          <img src={Taroland} alt="" />
        </div>
      </div>
      <div className="home_nft">
        <div className="home_nft_chest">
          <img src={NftChest} alt="" />
        </div>
        <div className="home_nft_chest_mobile">
          <img src={NftChestMobile} alt="" />
        </div>
        <div className="home_nft_blur">
          <img src={NftBlur} alt="" />
        </div>
      </div>
      <div className="home_nfthero">
        <div className="home_nfthero_text">
          <img src={NftHeroText} alt="" />
        </div>
        <div className="home_nfthero_mobile">
          <img src={NftHeroMobile} alt="" />
        </div>
        <div className="home_nfthero_image">
          <img src={NftHeroImage} alt="" />
        </div>
      </div>

      <div className="home_tarotoken">
        <div className="home_tarotoken_blur">
          <img src={NftBlur} alt="" />
        </div>
        <div className="home_tarotoken_mobile">
          <img src={TarotokenMobile} alt="" />
        </div>
        <div className="home_tarotoken_para">
          <img src={TarotokenPara} alt="" />
        </div>
        <div className="home_tarotoken_logo">
          <img src={TarotokenLogo} alt="" />
        </div>
      </div>
      <div className="home_roadmap">
        <div className="home_roadmap_text">
          <img src={Roadmap} alt="" />
        </div>

        <div className="home_roadmap_line">
          <img src={Line} alt="" />
        </div>
        <div className="home_roadmap_table">
          {isReadMoreOne ? (
            <img
              src={RoadmapOne}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreOne}
            />
          ) : (
            <img
              src={RoadmapOne}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreOne}
            />
          )}
          {isReadMoreTwo ? (
            <img
              src={RoadmapTwo}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreTwo}
            />
          ) : (
            <img
              src={RoadmapTwoNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreTwo}
            />
          )}
          {isReadMoreThree ? (
            <img
              src={RoadmapThree}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreThree}
            />
          ) : (
            <img
              src={RoadmapThreeNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreThree}
            />
          )}
          {isReadMoreFour ? (
            <img
              src={RoadmapFour}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreFour}
            />
          ) : (
            <img
              src={RoadmapFourNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreFour}
            />
          )}
          {isReadMoreFive ? (
            <img
              src={RoadmapFive}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreFive}
            />
          ) : (
            <img
              src={RoadmapFiveNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreFive}
            />
          )}
        </div>

        <div className="home_roadmap_table_mobile">
          {isReadMoreOne ? (
            <img
              src={RoadmapOneMobile}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreOne}
            />
          ) : (
            <img
              src={RoadmapOneMobile}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreOne}
            />
          )}
          {isReadMoreTwo ? (
            <img
              src={RoadmapTwoMobileNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreTwo}
            />
          ) : (
            <img
              src={RoadmapTwoMobileNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreTwo}
            />
          )}
          {isReadMoreThree ? (
            <img
              src={RoadmapThreeMobileNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreThree}
            />
          ) : (
            <img
              src={RoadmapThreeMobileNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreThree}
            />
          )}
          {isReadMoreFour ? (
            <img
              src={RoadmapFourMobileNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreFour}
            />
          ) : (
            <img
              src={RoadmapFourMobileNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreFour}
            />
          )}
          {isReadMoreFive ? (
            <img
              src={RoadmapFiveMobileNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreFive}
            />
          ) : (
            <img
              src={RoadmapFiveMobileNew}
              alt=""
              className="home_roadmap_table_one"
              onClick={toggleReadMoreFive}
            />
          )}
        </div>
        {/* <div className="home_roadmap_nav">
          <div className="home_roadmap_nav_left">
            <img src={Left} alt="" />
          </div>
          <div className="home_roadmap_nav_right">
            <img src={Right} alt="" />
          </div>
        </div> */}
      </div>
      <div className="home_partner">
        <div className="home_partner_text">
          <img src={Partner} alt="" />
        </div>
        <div className="home_partner_mobile">
          <img src={PartnerMobile} alt="" />
        </div>
        <div className="home_partner_investor">
          <img src={Investor} alt="" />
          <img src={InvestorTwo} className="home_partner_investor_two" alt="" />
        </div>
      </div>
      <div className="home_bg"></div>
      <div className="home_footer">
        <div className="home_footer_hero">
          <img src={FooterHero} alt="" />
        </div>
        <div className="home_footer_content">
          <div className="home_footer_logo">
            <img src={FooterLogo} alt="" />
          </div>
          <div className="home_footer_social_media">
            <p>Follow Us On</p>
          </div>
          <div className="home_footer_social_media_logo">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/taroverse_official/"
            >
              <img src={Instagram} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/TAROVERSEcom"
            >
              <img src={Twitter} alt="" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://t.me/taroverse">
              <img src={Telegram} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/e8D6Czyn4a"
            >
              <img src={Discord} alt="" />
            </a>
          </div>
          <div className="home_footer_footnotes">
            <p>Developed by Block Vision Studio LLC. All rights reserved</p>
          </div>
          <div className="home_footer_term">
            <p>
              Terms of Service | Privacy Policy |
              <span style={{ color: "white" }}> Contact Us</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
