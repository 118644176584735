import React from "react";
import "./Login.css";
import Logo from "./Images/logo.png";

import Angel from "./Images/Angel.png";
import AngelVideo from "./Images/angel.webm";
import Welcome from "./Images/welcome.png";
import LoginButton from "./Images/login.png";
function Login() {
  return (
    <>
      <div className="login_banner">
        <div className="login_angel">
          <video id="" autoPlay loop muted poster={Angel}>
            <source src={AngelVideo} type="video/mp4" />
          </video>
        </div>
        <div className="login_logo">
          <img src={Logo} alt="" />
        </div>
        <div className="login_box">
          <div className="login_box_welcome">
            <img src={Welcome} alt="" />
          </div>
          <form>
            <div className="login_email">
              <p>Email or Mobile</p>
              <input
                type="email"
                placeholder="Email Address"
                id="email-address"
              />
            </div>
            <div className="forgot_password">
              <p>Forgot Password?</p>
            </div>
            <div className="login_password">
              <p>Password</p>
              <input type="password" placeholder="Password" />
            </div>
            <div className="login_register">
              <p>
                Not Registered ? <span>Register Now</span>
              </p>
            </div>
            <div className="login_button">
              <img src={LoginButton} alt="" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
