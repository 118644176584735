import React from "react";
import "./Register.css";
import Logo from "./Images/logo.png";

import Angel from "./Images/Angel.png";
import AngelVideo from "./Images/angel.webm";
import Welcome from "./Images/welcome.png";
import LoginButton from "./Images/login.png";
function Register() {
  return (
    <>
      <div className="register_banner">
        <div className="register_angel">
          <video id="" autoPlay loop muted poster={Angel}>
            <source src={AngelVideo} type="video/mp4" />
          </video>
        </div>
        <div className="register_logo">
          <img src={Logo} alt="" />
        </div>
        <div className="register_box">
          <div className="register_box_welcome">
            <img src={Welcome} alt="" />
          </div>
          <form>
            <div className="register_with_email">
              <input
                type="email"
                placeholder="Register With Email"
                id="email-address"
              />
            </div>
            <div className="register_username">
              <p>Username</p>
              <input type="text" placeholder="Username" id="username" />
            </div>
            <div className="register_email">
              <p>Email</p>
              <input type="email" placeholder="Email" id="email" />
            </div>
            <div className="forgot_password">
              <p>Forgot Password?</p>
            </div>
            <div className="register_password">
              <p>Password</p>
              <input type="password" placeholder="Password" />
            </div>
            <div className="register_register">
              <p>
                Not Registered ? <span>Register Now</span>
              </p>
            </div>
            <div className="register_button">
              <img src={LoginButton} alt="" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Register;
