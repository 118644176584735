import React from "react";
import "./Landing.css";
import Logo from "./Images/logo.png";
import Angel from "./Images/angel.png";
import Welcome from "./Images/welcome.png";
import Explore from "./Images/explorenow.png";
import Bg from "./Images/landingpagebg.gif";
import BgVideo from "./Images/landingpage.mp4";
import AngelVideo from "./Images/angel.webm";
import { Link } from "react-router-dom";
import ConnectWallet from "./Images/downloadbutton.png";

function Landing() {
  return (
    <div className="Landing">
      <video id="background-video" autoPlay loop muted poster={Bg}>
        <source src={BgVideo} type="video/mp4" />
      </video>
      <div className="black"></div>

      <div class="navbar">
        <div class="logo">
          <img src={Logo} alt="" />
        </div>

        <div class="navbar_links_login">
          <Link to="./login">Login</Link>
        </div>
        <a href="www.abc.com">
          <div class="wallet">
            <img src={ConnectWallet} alt="" />
          </div>
        </a>
      </div>

      <div class="container">
        <div class="angel">
          <video id="angel" autoPlay loop muted poster={Angel}>
            <source src={AngelVideo} type="video/mp4" />
          </video>
        </div>
        <div class="para-1">
          <img src={Welcome} alt="" />
        </div>
        <div class="explore">
          <Link to="/home"><img src={Explore} alt="" /></Link>
        </div>
      </div>
    </div>
  );
}

export default Landing;
